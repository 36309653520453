export const defaultList = [
  {
    name: '巴棍杉林', url: 'https://m.icamping.app/store/bgsl137', loading: false, errMsg: '',
  },
  {
    name: '桃園復興 拉拉山 雲河露營農場',
    url: 'https://www.easycamp.com.tw/Store_700.html',
    loading: false,
    errMsg: '',
  },
  {
    name: '吉野家農莊露營區', url: 'https://gyejia.epo.tw', loading: false, errMsg: '',
  },
  {
    name: '戈巴倫露營區', url: 'http://www.kparung700.com', loading: false, errMsg: '',
  },
  {
    name: '赫利豐岳露營區(0986676099)',
    url: 'https://tw-camping.tw/hotel_info.asp?hid=237',
    loading: false,
    errMsg: '',
  },
];

export const mock = {};

export type Site = {
  url: string;
  name: string;
  source: string;
}
