import { createApp, Plugin } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import type { Site } from '@/data';
import { defaultList } from '@/data';

const app = createApp(App)
  .use(ElementPlus)
  .use(store)
  .use(router);

app.use({
  install(plugApp) {
    // eslint-disable-next-line no-param-reassign
    plugApp.config.globalProperties.$gtagEvent = (name: string, param: Record<string, string>) => {
      try {
        (window as any).gtag('event', name, param);
      } catch (e) {
        console.log('gtag event fail', e);
      }
    };
    // eslint-disable-next-line no-param-reassign
    plugApp.config.globalProperties.$getList = async (): Promise<Site[]> => {
      console.log('$getList');
      if (!localStorage.getItem('camping-list')) {
        localStorage.setItem('camping-list', JSON.stringify(defaultList));
      }
      const list = JSON.parse(localStorage.getItem('camping-list') || '[]') as Site[];
      return list;
    };
    // eslint-disable-next-line no-param-reassign
    plugApp.config.globalProperties.$getPluginList = async (): Promise<Site[]> => {
      const result = await chrome.runtime.sendMessage('eoechofkbgafblbmfomhfiecacpcnoeh', { command: 'get' });
      return result;
    };
  },
} as Plugin);

// eslint-disable-next-line no-restricted-syntax
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.mount('#app');
