
import axios from 'axios';
import { defineComponent } from 'vue';
import type { Site } from '@/data';
import { listEventBus } from '@/event';

let host = 'http://localhost:8052';
if (process.env.NODE_ENV === 'production') {
  host = '/api';
}

export default defineComponent({
  data() {
    return {
      pageLoading: false,
      keyword: '',
      campingList: [] as Site[],
      savedList: [] as Site[],
      savedURL: {} as Record<string, boolean>,
    };
  },
  methods: {
    async doSearch() {
      this.$gtagEvent('search', { keyword: this.keyword });
      try {
        this.pageLoading = true;
        const rsp = await axios.get(`${host}/search?keyword=${encodeURIComponent(this.keyword)}`);
        this.campingList = rsp.data?.data as Site[];
      } catch (e) {
        console.log(e);
        alert('搜尋失敗，請稍後再試');
      }
      this.pageLoading = false;
    },
    async addList(name: string, url: string, source: string) {
      this.$gtagEvent('add-list', { url });
      this.savedList.push({
        name,
        url,
        source,
      });
      this.savedURL[url] = true;
      this.saveList();
    },
    saveList() {
      localStorage.setItem('camping-list', JSON.stringify(this.savedList));
    },
    syncList(data: Site[]) {
      data.forEach((d) => {
        if (this.savedURL[d.url]) {
          return;
        }
        this.savedList.push(d);
        this.savedURL[d.url] = true;
      });
      this.saveList();
      this.$forceUpdate();
      alert('同步完成');
    },
  },
  mounted() {
    this.savedList = JSON.parse(localStorage.getItem('camping-list') || '[]') as Site[];
    this.savedList.forEach((l) => {
      this.savedURL[l.url] = true;
    });
    (window as any).startAD();
    listEventBus.on('list', this.syncList);
  },
  unmounted() {
    listEventBus.off('list');
  },
});
