import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import SpacePage from '../views/Space.vue';
import SearchPage from '../views/Search.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/search',
    name: 'Search',
    component: SearchPage,
  },
  {
    path: '/space',
    name: 'Space',
    component: SpacePage,
  },
  {
    path: '/',
    redirect: '/search',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
