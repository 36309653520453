
import { defineComponent } from 'vue';
import { listEventBus } from './event';

export default defineComponent({
  name: 'App',
  data() {
    return {
      extensionLink: 'https://chromewebstore.google.com/detail/%E9%9C%B2%E7%87%9F%E7%A9%BA%E4%BD%8D%E6%9F%A5%E8%A9%A2%E5%B7%A5%E5%85%B7/cciocbkjafkiimalfpdlpnhengcnadee?authuser=0&hl=zh-TW',
    };
  },
  methods: {
    goExtension() {
      window.open(this.extensionLink, '_blank');
    },
  },
  mounted() {
    window.addEventListener('message', (msg) => {
      if (msg.origin !== window.location.origin) {
        return;
      }
      if (!msg.data?.list) {
        return;
      }
      console.log(msg.data);
      listEventBus.emit('list', msg.data.list);
    }, false);
  },
});
