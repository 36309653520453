
import axios from 'axios';
import { defineComponent } from 'vue';
import {
  ElDatePicker, ElInput, ElButton, ElIcon, ElTooltip,
} from 'element-plus';
import { listEventBus } from '@/event';
import type { Site } from '@/data';

// const sites: string[] = [
//   'https://m.icamping.app/',
//   'https://www.easycamp.com.tw/',
//   'https://tw-camping.tw/',
//   'epo.tw',
// ];

let host = 'http://localhost:8052';
if (process.env.NODE_ENV === 'production') {
  host = '/api';
}

  type EmptyItem = {
    name: string;
    space: string;
  }

  type SiteLocal = {
    url: string;
    name: string;
    empty: EmptyItem[] | undefined;
    loading: boolean,
    lastSearch: number | undefined;
    errMsg: string,
  }

async function waitMs(ms: number) {
  return new Promise((r) => {
    setTimeout(() => {
      r(true);
    }, ms);
  });
}

export default defineComponent({
  name: 'App',
  components: {
    ElButton, ElInput, ElDatePicker, ElIcon, ElTooltip,
  },
  data() {
    return {
      list: [] as SiteLocal[],
      url: '',
      searching: false,
      urlMap: new Map<string, boolean>(),
      searchDate: undefined as (Date | undefined),
      pageLoading: false,
    };
  },
  methods: {
    async addList() {
      // const ok = sites.reduce((ret, site) => ret || this.url.indexOf(site) >= 0, false);
      // if (!ok) {
      //   alert('輸入的網站不再支援的名單內');
      //   return;
      // }
      if (this.urlMap.get(this.url)) {
        // eslint-disable-next-line no-alert
        alert('該營地已經在最愛內');
        return;
      }

      this.$gtagEvent('add-list', { url: this.url });
      try {
        const name = await this.getName(this.url);
        this.list.push({
          name,
          url: this.url,
          empty: undefined,
          loading: false,
          lastSearch: undefined,
          errMsg: '',
        });
        this.saveList();
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('無法正確取得營地名字，請換一個網址試試');
      }
    },
    async getName(url: string): Promise<string> {
      const rsp = await axios.get(`${host}/name?url=${encodeURIComponent(url)}`);
      const name = rsp.data?.data;
      return name;
    },
    async getEmpty(url: string, ts: number): Promise<EmptyItem[]> {
      const rsp = await axios.get(`${host}/empty?url=${encodeURIComponent(url)}&ts=${ts}`);
      const empty = rsp.data?.data;
      const ret: EmptyItem[] = [];
      Object.keys(empty).forEach((key) => {
        ret.push({
          name: key,
          space: empty[key],
        });
      });
      return ret as EmptyItem[];
    },
    isNotSaturday(value: Date): boolean {
      return value.getDay() !== 6;
    },
    async doSearch() {
      if (this.searchDate === undefined) {
        return;
      }
      this.pageLoading = true;
      this.list = this.list.map((l) => ({
        ...l,
        loading: true,
      }));

      const date = this.searchDate.getDate();
      const year = this.searchDate.getFullYear();
      const month = this.searchDate.getMonth() + 1;
      const ts = year * 10000 + month * 100 + date;
      for (let i = 0; i < this.list.length; i += 1) {
        const site = this.list[i];

        this.$gtagEvent('empty-search', { url: site.url, ts: ts.toString() });
        if (ts !== site.lastSearch) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const empty = await this.getEmpty(site.url, ts);
            site.empty = empty as EmptyItem[];
            site.lastSearch = ts;
          } catch (e) {
            site.errMsg = '無法讀取，請稍後再試';
          }
        }
        site.loading = false;
      }

      this.pageLoading = false;
    },
    remove(idx: number) {
      const { list } = this;
      list.splice(idx, 1);
      this.list = list;
      this.saveList();
    },
    saveList() {
      localStorage.setItem('camping-list', JSON.stringify(this.list));
    },
    async loadListData() {
      const list = await this.$getList();
      this.list = list.map((l) => ({
        name: l.name,
        url: l.url,
        empty: undefined,
        loading: false,
        lastSearch: undefined,
        errMsg: '',
      }));
    },
    syncList(data: Site[]) {
      const listMap = {} as Record<string, SiteLocal>;
      this.list.forEach((l) => {
        listMap[l.url] = l;
      });
      data.forEach((d) => {
        if (listMap[d.url]) {
          return;
        }
        this.list.push({
          name: d.name,
          url: d.url,
          empty: undefined,
          loading: false,
          lastSearch: undefined,
          errMsg: '',
        });
      });
      this.saveList();
      this.$forceUpdate();
      alert('同步完成');
    },
  },
  mounted() {
    this.loadListData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).startAD();
    listEventBus.on('list', this.syncList);
  },
  unmounted() {
    listEventBus.off('list');
  },
});
